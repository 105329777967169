import { Snackbar } from '@mui/material'
import { showToast } from 'components/GlobalMessage'
import { env } from 'config'
import { useSelector } from 'hooks'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Api from 'services'
import { CookiesCustom } from 'utils'

interface Props {
  children: ReactNode
}
export function ProtectedAuth({ children }: Props) {
  const { user } = useSelector((x) => x.profile)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  // TODO

  const token = CookiesCustom.getToken()
  const isAdminCheck = useCallback(() => {
    console.log('Flag admin: ', env.flag_only_admin)
    if (!env.flag_only_admin) {
      return
    }
    Api.portal
      .me()
      .then((res) => {
        const userPortal = res.data.data
        console.log('userPortal  ', userPortal)
        const isAdmin = userPortal?.admin_apps?.find(({ slug, code }: any) => slug === env.app_id || code === env.app_id)
        console.log('Is admin: ', isAdmin)
        if (isAdmin) {
          return
        }
        setOpen(true)
        showToast({
          content: 'Bạn cần có quyền Admin từ portal để có thể truy cập tính năng này. Vui lòng báo Quản trị viên hệ thống',
          type: 'warning'
        })
        setTimeout(() => {
          if (env.web.forlife) {
            window.location.href = env.web.forlife
          } else {
            navigate('/404')
          }
        }, 3000)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    isAdminCheck()
  }, [isAdminCheck])

  if (!token) {
    // window.location.href = `${process.env.REACT_APP_LOGIN_PAGE}/login?redirect_url=${process.env.REACT_APP_OKR_PAGE}${location.pathname}`
  }

  return (
    <>
      {open ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={() => {}}
          message={'Bạn cần có quyền Admin từ portal để có thể truy cập tính năng này. Vui lòng báo Quản trị viên hệ thống'}
        />
      ) : (
        children
      )}
    </>
  )
}
