import { CareStatusLabel, CustomerCareEntity, CustomerGenderLabel, FormatRankLabel, TableColumn } from 'models'
import { formatDateEn } from 'utils'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import React from 'react'
import { Box } from '@mui/material'
import { showToast } from 'components'
export const Table: TableColumn<CustomerCareEntity>[] = [
  {
    header: {
      label: 'Tên khách hàng'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.customer?.name ?? ''
    }
  },
  {
    header: {
      label: 'Trạng thái'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => CareStatusLabel[a.status],
      width: 200
    }
  },
  {
    header: {
      label: 'SĐT'
    },
    cell: {
      getElement: (a: CustomerCareEntity) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => {
            showToast({
              content: `Đã copy số điện thoại của khách "${a.customer?.name ?? ''}"`
            })
            navigator.clipboard.writeText(a.customer?.phone ?? '')
          }}
        >
          <div style={{ fontSize: '12px', fontWeight: '500' }}>Click lấy số </div>
          <ContentCopyIcon sx={{ fontSize: '16px', marginLeft: '6px' }} />
        </Box>
      )
    }
  },
  {
    header: {
      label: 'NV liên hệ'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.carer ? `${a.carer.name} - ${a.carer.username}` : ''),
      width: 200
    }
  },
  {
    header: {
      label: 'Giới tính'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.customer?.gender != undefined ? CustomerGenderLabel[a.customer.gender] : '')
    }
  },
  {
    header: {
      label: 'Tiêu điểm'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.customer?.score_amount ? `${a.customer?.score_amount} - ${formatDateEn(a.customer?.score_expire)}` : ''),
      width: 220
    }
  },
  {
    header: {
      label: 'Hạng'
    },
    cell: { getValue: (a: CustomerCareEntity) => (a.customer?.fm_rank ? FormatRankLabel[a.customer.fm_rank] : 'N/A'), width: 220 }
  },
  {
    header: {
      label: 'Sinh nhật'
    },
    cell: { getValue: (a: CustomerCareEntity) => (a.customer?.birthday ? formatDateEn(a.customer?.birthday) : '') }
  },
  {
    header: {
      label: 'Nơi mua'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.unit_store?.name ?? `Thiếu CH: ${a.import_data?.store_name ?? ''}`,
      width: 200
    }
  },
  {
    header: {
      label: 'Vùng'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.unit_area?.name ?? '',
      width: 200
    }
  },
  {
    header: {
      label: 'Đơn vị chăm sóc'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.store_carer?.name ?? a.unit_carer?.name ?? '',
      width: 200
    }
  }
]

export default Table
