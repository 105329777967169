import { PermissionEntity } from './permissions'
import { EnumModule } from './permissions/module'
export interface MenuEntity {
  href?: string
  title: string
  section_id?: SectionID
  permissions: Partial<PermissionEntity>[]
  id: EnumModule
  only_admin?: boolean
}
export interface SectionEntity {
  icon?: JSX.Element
  title: string
  id: SectionID
  items?: MenuEntity[]
}
export type SectionID = 'management' | 'role' | 'customer_care'

export const MenuUrl = {
  campaign: '/campaign',
  org_unit: '/organization_unit',
  customer_care: '/customer_care',
  area: '/area',
  customer: '/customer',
  user_portal: '/user_portal',
  location: '/location',
  role: '/group_permission'
}
